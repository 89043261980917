import { Form } from 'react-bootstrap';

import { use } from '../../redux/Factory';

const TaskTemplatesFormField = ({ value: taskTemplateId, onChange }) => {
  const { taskTemplatesList } = use.taskTemplates();
  return <>
    <Form.Select
      htmlSize="5"
      onChange={e => onChange(e.target.value)}
      value={taskTemplateId || undefined}
    >
      {Object.values(taskTemplatesList).map((taskTemplate, key) => {
        return (
          <option
            key={key}
            value={taskTemplate.id}
          >
            {taskTemplate.name}
          </option>
        )}
      )}
    </Form.Select>
  </>;
}
export default TaskTemplatesFormField;
