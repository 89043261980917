import { useState, useEffect } from 'react';
import { Container, Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { use } from '../../redux/Factory';
import {
  DataTable,
  SmallSpinner,
  CreateButton,
  DeleteConfirmButton,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  FormCheckbox,
} from '../shared/ReactToolbox';
import RolesFormField from './RolesFormField';
import LocationsFormField from '../production/LocationsFormField';

const UsersList = () => {
  const { rolesList, getRolesList } = use.roles();
  const { locationsList, getLocationsList } = use.locations();
  const { usersList, getUsersList, updateUser, createUser, deleteUser } = use.users();
  useEffect(() => {
    getUsersList();
    getRolesList();
    getLocationsList();
  }, []);
  const [userInCreateModal, setUserInCreateModal] = useState(false);
  const [userInEditModal, setUserInEditModal] = useState(null);
  const me = useSelector(({ auth }) => auth.user);

  if (!usersList || !rolesList || !locationsList) return <SmallSpinner className='vertical-space' />;

  const columns = [
    {
      orderBy: 'name',
      name: 'Naam',
      selector: user => user.name,
    },
    {
      orderBy: 'username',
      name: 'Gebruikersnaam',
      selector: user => user.username,
    },
    {
      orderBy: 'email',
      name: 'Emailadres',
      selector: user => user.email,
    },
    {
      name: 'Rollen',
      selector: user => rolesList && user.roles.map(
        (id, index) =>
          <Badge
            className='role'
            key={index}

            bg={rolesList[id] ? 'primary' : 'secondary'}
          >
            {rolesList[id] ? rolesList[id].name : <i>Niet gevonden</i>}
          </Badge>
      ),
    },
    {
      name: 'Locaties',
      selector: user => locationsList && user.locations.map(
        (id, index) =>
          <Badge
            className='location'
            key={index}

            bg={locationsList[id] ? 'primary' : 'secondary'}
          >
            {locationsList[id] ? locationsList[id].name : <i>Niet gevonden</i>}
          </Badge>
      ),
    },
    {
      name: 'Acties',
      selector: user => user.id !== me.id && (
        <DeleteConfirmButton
          modalTitle='Gebruiker verwijderen'
          title='Gebruiker verwijderen'
          className='float-end'
          onDelete={() => deleteUser(user)}
        />
      ),
    },
  ];

  const formFields = {
    name: {
      label: 'Naam',
    },
    username: {
      label: 'Gebruikersnaam',
    },
    email: {
      label: 'Emailadres',
    },
    roles: {
      label: 'Rollen',
      component: RolesFormField,
    },
    locations: {
      label: 'Locaties',
      component: LocationsFormField,
    },
    is_staff: {
      label: 'Is admin',
      component: FormCheckbox,
    },
  };
  return <>
    <Container className="users-list">
      <h4>Admins</h4>
      <DataTable
        showHeader={false}
        columns={columns}
        data={Object.values(usersList).filter(({ is_staff }) => is_staff)}
        orderByDefault='id'
        onClickRow={setUserInEditModal}
      />

      <CreateButton onClick={() => setUserInCreateModal({ locations: [], roles: [], is_staff: true })} />
      <br />
      <br />
      <h4>Gebruikers</h4>
      <DataTable
        filterColumn={({ name, username }) => `${name} ${username}`}
        columns={columns}
        data={Object.values(usersList).filter(({ is_staff }) => !is_staff)}
        orderByDefault='id'
        onClickRow={setUserInEditModal}
      />

      <CreateButton onClick={() => setUserInCreateModal({ locations: [], roles: [], is_staff: false })} />

      {userInCreateModal &&
        <CreateModal
          onHide={() => setUserInCreateModal(null)}
          modalTitle={`${userInCreateModal.is_staff ? 'Admin' : 'Gebruiker'} toevoegen`}
          loading={createUser.isLoading}
          initialState={userInCreateModal}
          formFields={formFields}
          onSave={user => createUser(
            user,
            { callback: () => setUserInCreateModal(null) }
          )}
        />
      }

      {userInEditModal &&
        <EditModal
          onHide={() => setUserInEditModal(null)}
          modalTitle={`${userInEditModal.is_staff ? 'Admin' : 'Gebruiker'} aanpassen`}
          loading={updateUser.isLoading}
          initialState={userInEditModal}
          formFields={formFields}
          onSave={user => updateUser(
            user,
            { callback: () => setUserInEditModal(null) }
          )}
        />
      }
    </Container>
  </>;
}
export default UsersList;
