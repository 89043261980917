import { Badge } from 'react-bootstrap';

const TaskTemplate = ({ cursor, taskTemplate, status, ...props }) => {
  return <Badge
    bg={
      status === 'unassigned'
      ? 'success'
      : status === 'started'
      ? 'info'
      : status === 'blocked'
      ? 'secondary'
      : 'primary'
    }
    style={{ ...cursor ? { cursor } : {} }}
    className='task-template'
    {...props}
  >
    {taskTemplate.name}
  </Badge>
}
export default TaskTemplate;
