import { useState, useEffect } from 'react';
import { Container, Table } from 'react-bootstrap';

import { use } from '../../redux/Factory';
import {
  SmallSpinner,
  CreateButton,
  DeleteConfirmButton,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
} from '../shared/ReactToolbox';

const RolesList = () => {
  const {
    rolesList,
    getRolesList,
    updateRole,
    createRole,
    deleteRole,
  } = use.roles();

  const [createRoleModalActive, setCreateRoleModalActive] = useState(false);
  const [roleInEditModal, setRoleInEditModal] = useState(null);
  useEffect(getRolesList, []);

  if (!rolesList) return <SmallSpinner className='vertical-space' />;
  const formFields = {
    name: {
      label: 'Rol',
    },
  };
  return <>
    <Container className="roles-list">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Naam</th>
          </tr>
        </thead>
        <tbody>
          {rolesList && Object.values(rolesList).map((role, key) =>
            <tr
              key={key}
              onClick={() => setRoleInEditModal(role)}
            >
              <td>
                {role.name}
                <DeleteConfirmButton
                  className="float-end"
                  onDelete={() => deleteRole(role)}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <CreateButton onClick={() => setCreateRoleModalActive(true)} />

      {createRoleModalActive &&
        <CreateModal
          onHide={() => setCreateRoleModalActive(false)}
          modalTitle="Rol toevoegen"
          loading={createRole.isLoading}
          initialState={Object.keys(formFields).reduce((o, key) => ({ ...o, key: '' }), {})}
          formFields={formFields}
          onSave={role => createRole(
            role,
            { callback: () => setCreateRoleModalActive(false) }
          )}
        />
      }

      {roleInEditModal &&
        <EditModal
          onHide={() => setRoleInEditModal(null)}
          modalTitle="Rol aanpassen"
          loading={updateRole.isLoading}
          initialState={roleInEditModal}
          formFields={formFields}
          onSave={role => updateRole(
            role,
            { callback: () => setRoleInEditModal(null) }
          )}
        />
      }
    </Container>
  </>;
};
export default RolesList;

