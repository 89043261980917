import { Form } from 'react-bootstrap';

import { use } from '../../redux/Factory';
import { DisabledFormField, SmallSpinner } from '../shared/ReactToolbox';

const ProductionTemplatesFormField = ({ value: productionTemplateId, onChange, disabled, ...restProps }) => {
  const { productionTemplatesList } = use.productionTemplates();
  if (!productionTemplatesList) return <SmallSpinner className='vertical-space' />;
  if (disabled) {
    return (
      <DisabledFormField
        value={
          productionTemplatesList[productionTemplateId]
          && productionTemplatesList[productionTemplateId].name
        }
      />
    );
  }
  return <>
    <Form.Select
      htmlSize="5"
      onChange={e => onChange(e.target.value)}
      defaultValue={productionTemplateId}
      {...restProps}
    >
      {Object.values(productionTemplatesList).map((productionTemplate, key) => {
        return (
          <option
            key={key}
            value={productionTemplate.id}
            selected={productionTemplateId === productionTemplate.id}
          >
            {productionTemplate.name}
          </option>
        )}
      )}
    </Form.Select>
  </>;
}
export default ProductionTemplatesFormField;
