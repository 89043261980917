const TeamleaderClient = ({ teamleaderDeal: td }) => (
  <>
    {td.customer_type === 'company' &&
      <>
        {td.company ? td.company.name : <i>Bedrijf niet gevonden</i>}:
      </>
    }
    &nbsp;
    {td.contact
    ? <a href={td.contact.web_url} target='_blank' rel='noreferrer'>
        {td.contact.first_name ? `${td.contact.first_name} ${td.contact.last_name}` : td.contact.last_name }
      </a>
    : <i>Niet gevonden</i>}
  </>
);

export default TeamleaderClient;
