import { useNavigate } from 'react-router-dom';
import { use } from '../../redux/Factory';
import { CreateEditModal as CreateModal } from '../shared/ReactToolbox';
import { createFormFields as projectFormFields } from './Project';

const CreateProjectModal = ({ onHide, onCreate, teamleaderDeal }) => {
  const { createProject } = use.projects();
  return (
    <CreateModal
      onHide={onHide}
      modalTitle={`Project toevoegen aan "${teamleaderDeal.data.title}"`}
      dialogClassName='mw-100 w-75'
      loading={createProject.isLoading}
      formFields={projectFormFields}
      initialState={{
        name: teamleaderDeal.data.title,
        production_template: null,
      }}
      includeData={{
        teamleader_deal: teamleaderDeal.id,
      }}
      onSave={project => createProject(
        project,
        { callback: async (project) => await onCreate(project)}
      )}
    />
  );
}
export default CreateProjectModal;
