import { useState } from 'react';
import moment from 'moment';
import { Form, Modal, Button, Row, Col, InputGroup } from 'react-bootstrap';

import { use } from '../../redux/Factory';
import {
  DataTable,
  SmallSpinner,
  CheckButton,
  PencilButton,
  ConfirmButton,
  FormTextArea,
  FormCheckbox,
} from '../shared/ReactToolbox';
import { dependenciesFormFieldComponent } from '../production/ProductionTemplate';
import UsersFormField from '../users/UsersFormField';

const ProjectTaskModal = ({
  project,
  task,
  show=true,
  onHide,
  updateTasksList,
}) => {
  const [notes, setNotes] = useState(task.notes);
  const [isFlagged, setIsFlagged] = useState(task.is_flagged);
  const translateStatus = {
    unassigned: 'Kan gestart worden',
    blocked: 'Wacht op dat een andere taak klaar is',
    started: 'Gestart',
    finished: 'Klaar',
  };
  const { startTask, finishTask, updateTask, resetTask } = use.projects();
  const { taskTemplatesList } = use.taskTemplates();
  const { usersList } = use.users();

  const hasLogs = task.status === 'started' || task.status === 'finished' || task.logs.length > 0;
  const DependenciesFormField = dependenciesFormFieldComponent({ tasksList: project.tasks, taskTemplatesList });

  const columns = [
    {
      name: 'Startdatum',
      selector: ({ started_at }) => moment(started_at).format('DD-MM-YYYY HH:mm'),
    },
    {
      name: 'Einddatum',
      selector: ({ finished_at }) =>
        finished_at ? moment(finished_at).format('DD-MM-YYYY HH:mm') : <i>Nog niet afgesloten</i>,
    },
    {
      name: 'Gebruiker',
      selector: ({ user: userId }) => {
        const user = usersList && usersList[userId];
        return user && (user.name || <italic>{user.username}</italic>);
      },
    },
    {
      name: 'Notities',
      selector: ({ notes }) => notes,
    },
  ];
  return (
    <Modal
      show={show}
      onHide={onHide}
      onClick={e => e.stopPropagation()}
      centered
      dialogClassName={hasLogs ? 'mw-100 w-75' : ''}
    >
      <Modal.Header closeButton>
        <Modal.Title>{taskTemplatesList[task.task_template].name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={hasLogs ? 4 : 12}>
            <Form>
              <Form.Group controlId="status">
                <Form.Label>Status</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="input"
                    name="status"
                    value={translateStatus[task.status]}
                    onChange={() => {}}
                    disabled
                  />
                  {task.status === 'finished' &&
                    <ConfirmButton
                      variant="primary"
                      size=""
                      modalTitle='Deze taak weer open zetten'
                      modalBody='Weet je zeker dat je deze taak weer open wilt zetten?'
                      confirmText='Taak open zetten'
                      buttonComponent={PencilButton}
                      loading={resetTask.isLoading}
                      onConfirm={() => resetTask(
                        task,
                        {
                          args: { project },
                          callback:
                            project => {
                              updateTasksList(project.tasks);
                            },
                        }
                      )}
                      title=""
                    >
                      Taak open zetten
                    </ConfirmButton>
                  }
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="start">
                <Form.Label>Startdatum</Form.Label>
                <Form.Control
                  type="input"
                  name="start"
                  value={task.started_at ? moment(task.started_at).format('DD-MM-YYYY - HH:mm') : ''}
                  onChange={() => {}}
                  disabled
                />
              </Form.Group>
              <Form.Group controlId="end">
                <Form.Label>Einddatum</Form.Label>
                <Form.Control
                  type="input"
                  name="end"
                  value={task.finished_at ? moment(task.finished_at).format('DD-MM-YYYY - HH:mm') : ''}
                  onChange={() => {}}
                  disabled
                />
              </Form.Group>

              <Form.Group controlId="dependencies">
                <Form.Label>Afhankelijk van</Form.Label>
                <DependenciesFormField
                  value={task.dependencies}
                  state={task.dependencies}
                />
              </Form.Group>

              {task.assigned_to &&
                <Form.Group>
                  <Form.Label>Toegekend aan</Form.Label>
                  <UsersFormField
                    onChange={() => {}}
                    value={task.assigned_to}
                    disabled
                  />
                </Form.Group>
              }
              <FormCheckbox
                value={isFlagged}
                onChange={setIsFlagged}
                label="Gevlagd"
              />
              <FormTextArea
                value={notes}
                onChange={setNotes}
                label="Notities"
              />
            </Form>
          </Col>
          {hasLogs > 0 &&
            <Col md="8">
              <DataTable
                columns={columns}
                data={task.logs}
                showHeader={false}
                orderByDefault='started_at'
              />
            </Col>
          }
        </Row>
      </Modal.Body>

      <Modal.Footer>
        {(startTask.isLoading || finishTask.isLoading || updateTask.isLoading) && <SmallSpinner />}
        <Button
          variant="secondary"
          onClick={onHide}
        >
          Sluiten
        </Button>
        {/* {task.status === 'unassigned' &&
          <PlayButton
            variant="primary"
            size=""
            onClick={() => startTask(
              task,
              {
                args: { project },
                callback:
                  project => {
                    updateTasksList(project.tasks);
                  },
              }
            )}
            title=""
          >
            Taak starten
          </PlayButton>
        } */}
        {task.status !== 'finished' &&
          <CheckButton
            variant="primary"
            buttonSize=""
            onClick={() => finishTask(
              task,
              {
                args: { project },
                callback:
                  project => {
                    updateTasksList(project.tasks);
                    onHide();
                  },
              }
            )}
            title=""
          >
            Taak afronden
          </CheckButton>
        }
        <Button
          variant="primary"
          size=""
          disabled={task.notes === notes && task.is_flagged === isFlagged}
          onClick={() => updateTask(
            { ...task, notes, is_flagged: isFlagged },
            {
              args: { project },
              callback:
                project => {
                  updateTasksList(project.tasks);
                  onHide();
                },
            }
          )}
          title=""
        >
          Opslaan
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProjectTaskModal;
