import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import _ from 'lodash';
import { Form, Modal, Button } from 'react-bootstrap';

import { use } from '../../redux/Factory';
import { usePrevious, getToday } from '../../utils/Utils';
import FrappeGantt, { Task, ViewMode} from './FrappeGantt';
import {
  CreateButton,
  EditButton,
  DeleteConfirmButton,
  UpButton,
  DownButton,
  MoveButton,
  SmallSpinner,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
} from '../shared/ReactToolbox';
import TaskTemplatesFormField from './TaskTemplatesFormField';
import { arrayToObject } from '../../utils/Utils';

export const formFields = {
  name: {
    label: 'Naam',
  },
  description: {
    label: 'Beschrijving',
    formProps: { as: 'textarea' },
  },
};

const MoveTaskModal = ({ onHide, tasksList, task, moveTask }) => {
  const [target, setTarget] = useState(null);
  return (
    <Modal
      onHide={onHide}
      onClick={e => e.stopPropagation()}
      centered
      show
    >
      <Modal.Header closeButton>
        <Modal.Title>Taak verplaatsen onder</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Select
          htmlSize={Math.min(20, tasksList.length + 1)}
          value={target ? target.id : ''}
          // Dummy onChange is used to suppress warning.
          onChange={() => {}}
        >
          <option value='0' onClick={() => setTarget({ id: '0' })}>
            Bovenaan
          </option>
          {tasksList.map((t, index) =>
            <option
              value={t.id}
              onClick={() => setTarget(t)}
              key={index}
              disabled={task && parseInt(task.id) === parseInt(t.id)}
            >
              {index + 1} {t._name}
            </option>
          )}
        </Form.Select>
        <Modal.Footer>
          {moveTask.isLoading && <SmallSpinner />}
          <Button
            variant='secondary'
            onClick={onHide}
          >
            Sluiten
          </Button>
          <Button
            variant='primary'
            onClick={() => moveTask(
              {
                source: task,
                target: target.id === '0'
                  ? tasksList[0]
                  : target,
                position: target.id === '0' ? 'right' : 'left',
              }
            )}
            disabled={target === null}
          >
            Opslaan
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export const getTaskPopupComponent = task =>
  (
    task
    && document.getElementById(`task-${task.id}`)
    && (({ children }) => createPortal(children, document.getElementById(`task-${task.id}`)))
);

export const getTaskName = ({ task: { task_template, name, order, id }, taskTemplatesList } = {}) =>
 `${
    taskTemplatesList
    && taskTemplatesList[task_template]
    && taskTemplatesList[task_template].name
  }${name ? ` - ${name}` : ''}`;

export const dependenciesFormFieldComponent = ({ tasksList, taskTemplatesList }) => ({ value: taskIds = [], onChange = null, state = {}, disabled = false }) => {
  return <>
    <Form.Select
      htmlSize="5"
      multiple
      value={taskIds.map(id => parseInt(id))}
      // Dummy onChange is used to suppress warning.
      onChange={() => {}}
      disabled={disabled}
    >
      {Object.values(tasksList).map((task, index) => {
        const hasTask = taskIds.find(id => parseInt(id) === parseInt(task.id));
        return (
          <option
            key={index}
            value={parseInt(task.id)}
            // Do not allow self reference
            disabled={parseInt(state.id) === parseInt(task.id)}
            onClick={() => {
              // Use onClick here instead of onChange in Form.Select to be able to unset all the options which isn't possible otherwise
              if (onChange === null) {
                return;
              } else if(hasTask) {
                onChange(taskIds.filter(id => parseInt(id) !== parseInt(task.id)));
              } else {
                onChange([...taskIds, parseInt(task.id)]);
              }
            }}
          >
            {index + 1} {getTaskName({ task, taskTemplatesList})}
          </option>
        )}
      )}
    </Form.Select>
  </>;
}

const tomorrow = moment().add(1, 'day')
export const getUpdateTasksList = ({
  setTasksList,
  setTasksRecursiveList,
  taskTemplatesList,
}) => tasks => {
  const tasksWithDate = {};
  const tasksWithoutDate = arrayToObject(tasks, 'id')

  // Break loop if no tasks were added to avoid infinite loops when dependencies are recursive:
  // A->B->A or A->B->C->A will make Gantt crash the browser tab (!!!)
  let addedTask = false;
  do {
    addedTask = false
    Object.values(tasksWithoutDate).map((task, index) => {
      let start, end;

      if (_.isEmpty(task.dependencies)) {
        start = task.started_at ? moment(task.started_at) : tomorrow.clone();
      } else {
        start = task.started_at ? moment(task.started_at) : null;
        // Get a list of dependencies (task that this task depends on) that have their dates set
        const dependencies = task.dependencies.filter(id => tasksWithDate[id]).map(id => tasksWithDate[id])
        // Skip if not all dependencies have their date set
        if (dependencies.length !== task.dependencies.length) {
          return;
        }
        // Now calculate the maximum end date of the dependencies and let this task start there
        dependencies.map(
          ({ _end, end }) => {
            start = !start ? end : _end > start ? _end : start;
          }
        );
        if ((task.status === 'unassigned' || task.status === 'blocked') && start < tomorrow) start = tomorrow.clone();
      }
      // Take the end date of the task if it was already set, otherwise set it one day in the future of the start date
      const startPlusOneDay = moment(start).clone().add(1, 'day');
      // _end is the actual end time used to calculate the next start time
      const _end = task.finished_at ? moment(task.finished_at) : startPlusOneDay
      if (task.status === 'started') {
        end = moment().add(1, 'day');
      } else if (task.finished_at && moment(task.finished_at) > startPlusOneDay) {
        end = moment(task.finished_at);
      } else {
        end = startPlusOneDay;
      }

      delete tasksWithoutDate[task.id];
      tasksWithDate[task.id] = new Task({
        ...task,
        start,
        end,
        _end,
        ...task.status
          ? {
              custom_class: task.is_flagged ? 'task-flagged' : `task-${task.status}`,
              progress: 0,
            }
          : {},
        _name: getTaskName({ task, taskTemplatesList }),
      });
      addedTask = true;
    });
  } while (!_.isEmpty(tasksWithoutDate) && addedTask)
  setTasksList(Object.values(tasksWithDate).sort((o1, o2) => o1.order -  o2.order));
  setTasksRecursiveList(Object.values(tasksWithoutDate));
}

const ProductionTemplate = ({ id }) => {
  const {
    productionTemplate = {},
    getProductionTemplate,
    updateProductionTemplate,
    createTask,
    updateTask,
    deleteTask,
    moveTask,
  } = use.productionTemplates(id);
  const { getTaskTemplatesList, taskTemplatesList } = use.taskTemplates();
  const tasks = productionTemplate && productionTemplate.tasks;
  const [createTaskModalActive, setCreateTaskModalActive] = useState(false);
  const [taskInEditModal, setTaskInEditModal] = useState(false);
  const [clickedTask, setClickedTask] = useState(null);
  const [tasksList, setTasksList] = useState(null);
  const [tasksRecursiveList, setTasksRecursiveList] = useState(null);
  const [moveTaskModalActive, setMoveTaskModalActive] = useState(false);
  const [gantt, setGantt] = useState(null);

  useEffect(() => {
    getProductionTemplate(id)
    getTaskTemplatesList();
  }, []);
  const updateTasksList = getUpdateTasksList({ setTasksList, setTasksRecursiveList, taskTemplatesList });

  const moveTaskIsLoadingPrev = usePrevious(moveTask.isLoading);
  useEffect(() => {
    if (!_.isEmpty(productionTemplate) && !_.isEmpty(taskTemplatesList) && !tasksList) {
      updateTasksList(productionTemplate.tasks);
    }
  }, [productionTemplate, taskTemplatesList, tasksList, moveTask.isLoading, moveTaskIsLoadingPrev])
  const [editProductionTemplateModalActive, setEditProductionTemplateModalActive] = useState(false);

  if (!productionTemplate || !taskTemplatesList) return <SmallSpinner className='vertical-space' />;

  const taskFormFields = {
    name: {
      label: 'Naam',
    },
    task_template: {
      label: 'Taak template',
      component: TaskTemplatesFormField,
    },
    dependencies: {
      label: 'Afhankelijk van',
      component: dependenciesFormFieldComponent({ tasksList: productionTemplate.tasks, taskTemplatesList }),
      initialValue: [],
    },
  }
  const TaskPopup = getTaskPopupComponent(clickedTask);
  // console.log(clickedTask && parseInt(clickedTask.id), productionTemplate.tasks.slice(-1)[0].id)
  return <div className='vertical-space'>
    <h4
      onClick={() => setEditProductionTemplateModalActive(true)}
      style={{ cursor: 'pointer' }}
    >
      {productionTemplate.name}
    </h4>

    {productionTemplate.description && <p>{productionTemplate.description}</p>}
    {!_.isEmpty(tasksRecursiveList) &&
      <Form.Group >
        <Form.Label>Recursieve afhankelijk aangetroffen, pas een van de taken aan</Form.Label>
        <Form.Select
          htmlSize="5"
          onChange={() => {}}
          value={taskInEditModal ? taskInEditModal.id : undefined}
        >
          {tasksRecursiveList.map((task, key) => {
            return (
              <option
                key={key}
                value={task.id}
                onClick={() => setTaskInEditModal(new Task(task))}
              >
                {getTaskName({ task, taskTemplatesList })}
              </option>
            )}
          )}
        </Form.Select>
      </Form.Group>
    }
    {tasksList && tasksList.length > 0
      ? <FrappeGantt
          onTaskChange={(changedTask, tasks) => gantt.refresh(tasksList)}
          tasks={tasksList}
          viewMode={ViewMode.Day}
          customPopupHtml={task => `<div id="task-${task.id}"></div>`}
          customPopupHtmlCallback={setClickedTask}
          setGantt={setGantt}
        />
      : null
    }
    {TaskPopup &&
      <TaskPopup>
        <span style={{ display: 'flex' }}>
          <EditButton
            onClick={() => setTaskInEditModal(clickedTask)}
          />
          <UpButton
            disabled={clickedTask.order === 0}
            onClick={() => moveTask(
              {
                source: clickedTask,
                target: [...productionTemplate.tasks].reverse().find(({ order }) => order < clickedTask.order - 1) || productionTemplate.tasks[0],
                position: 'right',
                // position: 'left',
              },
              {
                args: { productionTemplate },
                callback: productionTemplate => {
                  updateTasksList(productionTemplate.tasks);
                  setClickedTask(null);
                },
              }
            )}
          />
          <DownButton
            disabled={parseInt(clickedTask.id) === productionTemplate.tasks.slice(-1)[0].id}
            onClick={() => moveTask(
              {
                source: clickedTask,
                target: productionTemplate.tasks.find(({ order }) => order > clickedTask.order),
                // position: 'right',
                position: 'left',
              },
              {
                args: { productionTemplate },
                callback: productionTemplate => {
                  updateTasksList(productionTemplate.tasks);
                  setClickedTask(null);
                },
              }
            )}
          />
          <MoveButton
            onClick={() => setMoveTaskModalActive(true)}
          />
          <DeleteConfirmButton
            loading={deleteTask.isLoading}
            onDelete={() => deleteTask(
              clickedTask,
              {
                args: { productionTemplate },
                callback:
                  productionTemplate => {
                    updateTasksList(productionTemplate.tasks);
                    setClickedTask(null);
                  }
              }
            )}
          />
          {moveTask.isLoading && <SmallSpinner />}
        </span>
      </TaskPopup>
    }

    <CreateButton onClick={() => setCreateTaskModalActive(true)} style={{ marginTop: '5 px' }}/>

    {createTaskModalActive &&
      <CreateModal
        onHide={() => setCreateTaskModalActive(false)}
        modalTitle="Taak toevoegen"
        loading={createTask.isLoading}
        formFields={taskFormFields}
        includeData={{
          production_template: productionTemplate.id,
          start: moment().toISOString(),
          end: moment().add(1, 'day').toISOString(),
        }}
        onSave={task => createTask(
          task,
          {
            args: { productionTemplate },
            callback:
              productionTemplate => {
                updateTasksList(productionTemplate.tasks);
                setCreateTaskModalActive(false);
                setClickedTask(null);
              },
          }
        )}
      />
    }

    {taskInEditModal &&
      <EditModal
        onHide={() => setTaskInEditModal(null)}
        modalTitle='Taak aanpassen'
        loading={updateTask.isLoading}
        initialState={taskInEditModal}
        formFields={taskFormFields}
        onSave={task => updateTask(
          task,
          {
            args: { productionTemplate },
            callback: productionTemplate => {
              updateTasksList(productionTemplate.tasks);
              setTaskInEditModal(null);
              setClickedTask(null);
            },
          }
        )}
      />
    }

    {editProductionTemplateModalActive &&
      <EditModal
        onHide={() => setEditProductionTemplateModalActive(false)}
        modalTitle="Productietemplate aanpassen"
        loading={updateProductionTemplate.isLoading}
        initialState={productionTemplate}
        formFields={formFields}
        onSave={productionTemplate => updateProductionTemplate(
          productionTemplate,
          { callback: () => setEditProductionTemplateModalActive(false) }
        )}
      />
    }

    {moveTaskModalActive &&
      <MoveTaskModal
        task={clickedTask}
        tasksList={tasksList}
        onHide={() => setMoveTaskModalActive(false)}
        moveTask={
          ({ source, target, position }) => moveTask(
            { source, target, position },
            {
              args: { productionTemplate },
              callback: productionTemplate => {
                updateTasksList(productionTemplate.tasks);
                setClickedTask(null);
                setMoveTaskModalActive(false);
              },
            }
          )}
      />
    }
  </div>;
};
export default ProductionTemplate;
