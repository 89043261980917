import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { use } from '../../redux/Factory';
import { CreateButton, EditButton } from '../shared/ReactToolbox';
import TeamleaderCustomFieldDefinition from './TeamleaderCustomFieldDefinition';

const TeamleaderCustomFieldDefinitionsFormField = ({ value: tCFDIds, onChange }) => {
  const { teamleaderCustomFieldDefinitionsList } = use.teamleaderCustomFieldDefinitions();
  const [addModalActive, setAddModalActive] = useState(false);

  const tCFDsByGroup = {}
  Object.values(teamleaderCustomFieldDefinitionsList).map(tCFD => {
    const { group } = tCFD;
    if (group === null) {
      return;
    } else if (!tCFDsByGroup[group]) {
      tCFDsByGroup[group] = [tCFD];
    } else {
      tCFDsByGroup[group].push(tCFD);
    }
  })

  return <div className="form-control-lg">
    {tCFDIds.map((id, key) => {
      const tCFD = Object.values(teamleaderCustomFieldDefinitionsList).find(tCFD => id === tCFD.id);

      return (
        <TeamleaderCustomFieldDefinition
          key={key}
          onClick={() => setAddModalActive(true)}
        >
          {tCFD ? tCFD.label : <i>Niet gevonden</i>}
        </TeamleaderCustomFieldDefinition>
      )
    })}
    {tCFDIds.length === 0
      ? <CreateButton onClick={() => setAddModalActive(true)} />
      : <EditButton onClick={() => setAddModalActive(true)} />
    }

    <Modal
      show={addModalActive}
      onHide={() => setAddModalActive(false)}
      onClick={e => e.stopPropagation()}
      centered
      dialogClassName='mw-100 w-50'
    >
      <Modal.Header closeButton>
        <Modal.Title>Offerteveld toevoegen</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {Object.entries(tCFDsByGroup)
          .sort(([group1], [group2]) => group1 > group2 ? 1 : -1)
          .map(([group, tCFDs]) =>
            <p key={group}>
              <strong>{group}</strong><br />
              {tCFDs
                .sort((tCFD1, tCFD2) => tCFD1.label > tCFD2.label ? 1 : -1)
                .map((tCFD, key) => {
                  const hasTCFD = !!tCFDIds.find(id => id === tCFD.id);
                  return (
                    <TeamleaderCustomFieldDefinition
                      key={key}
                      hasTeamleaderCustomFieldDefinition = {hasTCFD}
                      onClick={() => {
                        if (!hasTCFD) {
                          onChange([...tCFDIds, tCFD.id])
                        } else {
                          onChange(tCFDIds.filter(id => id !== tCFD.id))
                        }
                      }}
                    >
                      {tCFD ? tCFD.label : <i>Niet gevonden</i>}
                    </TeamleaderCustomFieldDefinition>
                  );
              })}
            </p>
          )
        }
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setAddModalActive(false)}
        >
          Sluiten
        </Button>
      </Modal.Footer>
    </Modal>
  </div>;
};
export default TeamleaderCustomFieldDefinitionsFormField;
