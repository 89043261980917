import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';

import { use } from '../../redux/Factory';
import {
  SmallSpinner,
  CreateButton,
  EditButton,
  DeleteConfirmButton,
  DataTable,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
} from '../shared/ReactToolbox';
import LocationsFormField from './LocationsFormField';
import Location from './Location';
import RolesFormField from '../users/RolesFormField';
import Role from '../users/Role';
import TeamleaderCustomFieldDefinitionsFormField from '../teamleader/TeamleaderCustomFieldDefinitionsFormField';
import TeamleaderCustomFieldDefinition from '../teamleader/TeamleaderCustomFieldDefinition';

const TaskTemplatesList = () => {
  const {
    taskTemplatesList,
    getTaskTemplatesList,
    updateTaskTemplate,
    createTaskTemplate,
    deleteTaskTemplate,
    moveTaskTemplate,
  } = use.taskTemplates();
  const { rolesList, getRolesList } = use.roles();
  const { locationsList, getLocationsList } = use.locations();
  const { getTeamleaderCustomFieldDefinitionsList, teamleaderCustomFieldDefinitionsList } = use.teamleaderCustomFieldDefinitions();
  const [createTaskTemplateModalActive, setCreateTaskTemplateModalActive] = useState(false);
  const [taskTemplateInEditModal, setTaskTemplateInEditModal] = useState(null);
  useEffect(() => {
    getTaskTemplatesList();
    getRolesList();
    getLocationsList();
    getTeamleaderCustomFieldDefinitionsList();
  }, []);

  if (!taskTemplatesList || !rolesList || !locationsList || !teamleaderCustomFieldDefinitionsList) return <SmallSpinner className='vertical-space' />;
  const formFields = {
    name: {
      label: 'Naam',
    },
    description: {
      label: 'Beschrijving',
      formProps: { as: 'textarea' },
    },
    role: {
      label: 'Rol',
      component: RolesFormField,
      initialValue: null,
    },
    locations: {
      label: 'Locaties',
      component: LocationsFormField,
      initialValue: [],
    },
    custom_fields: {
      label: 'Offertevelden',
      component: TeamleaderCustomFieldDefinitionsFormField,
      initialValue: [],
    }
  };

  return <>
    <Container className="container-list">

      {taskTemplatesList &&
        <DataTable
          onClickRow={setTaskTemplateInEditModal}
          showHeader={false}
          rowsPerPage={null}
          filterColumn='name'
          columns={[
            {
              orderBy: 'name',
              name: 'Naam',
              selector: ({ name }) => name,
            },
            {
              name: 'Rol',
              selector: taskTemplate =>
                rolesList && rolesList[taskTemplate.role] &&
                  <Role>
                    {rolesList[taskTemplate.role].name}
                  </Role>,
            },
            {
              name: 'Locaties',
              selector: taskTemplate =>
                locationsList &&
                  Object.values(locationsList)
                    .filter(({ id }) => taskTemplate.locations.includes(id))
                    .map(({ name }, index) =>
                      <Location key={index}>
                        {name}
                      </Location>
                    ),
            },
            {
              name: 'Offertevelden',
              selector: taskTemplate =>
                teamleaderCustomFieldDefinitionsList &&
                  Object.values(teamleaderCustomFieldDefinitionsList)
                    .filter(({ id }) => taskTemplate.custom_fields.includes(id))
                    .map(({ label }, index) =>
                      <TeamleaderCustomFieldDefinition key={index}>
                        {label}
                      </TeamleaderCustomFieldDefinition>
                    ),
            },
            {
              name: 'Acties',
              selector: taskTemplate => <span className="float-end">
                <EditButton onClick={() => setTaskTemplateInEditModal(taskTemplate)} />
                &nbsp;
                <DeleteConfirmButton onDelete={() => deleteTaskTemplate(taskTemplate)} />
              </span>,
            },
          ]}
          data={Object.values(taskTemplatesList)}
          onMove={({ item, target, reset }) => moveTaskTemplate(
            {
              taskTemplate: item,
              target,
              position: item.order > target.order ? 'right' : 'left',
            },
            {
                callback: reset,
                onError: reset,
            }
          )}
          orderByDefault='order'
        />
      }

      <CreateButton onClick={() => setCreateTaskTemplateModalActive(true)} />

      {createTaskTemplateModalActive &&
        <CreateModal
          onHide={() => setCreateTaskTemplateModalActive(false)}
          modalTitle="Taak template toevoegen"
          loading={createTaskTemplate.isLoading}
          formFields={formFields}
          includeData={{ dependencies: [] }}
          onSave={taskTemplate => createTaskTemplate(
            taskTemplate,
            { callback: () => setCreateTaskTemplateModalActive(false) }
          )}
        />
      }

      {taskTemplateInEditModal &&
        <EditModal
          onHide={() => setTaskTemplateInEditModal(null)}
          modalTitle="Taak template aanpassen"
          loading={updateTaskTemplate.isLoading}
          initialState={taskTemplateInEditModal}
          formFields={formFields}
          onSave={taskTemplate => updateTaskTemplate(
            taskTemplate,
            { callback: () => setTaskTemplateInEditModal(null) }
          )}
        />
      }
    </Container>
  </>;
};
export default TaskTemplatesList;
