import { Badge } from 'react-bootstrap';
import _ from 'lodash';

import { use } from '../../redux/Factory';
import Role from './Role';

const RolesFormField = ({ value, onChange }) => {
  const { rolesList } = use.roles();
  // Either select one role or multiple roles depending of value prop is an array or not.
  const multiple = _.isArray(value);

  return (
    <div className="form-control-lg">
      {Object.values(rolesList).map((role, key) => {
        const hasRole = multiple
          ? !!value.find(id => id === role.id)
          : value === role.id;

        return (
          <Role
            key={key}
            hasRole={hasRole}
            cursor='pointer'
            className='role'
            onClick={() => {
              if (multiple) {
                if (hasRole) {
                  onChange(value.filter(id => id !== role.id));
                } else {
                  onChange([...value, role.id]);
                }
              } else {
                onChange(role.id)
              }
            }}
          >
            {role.name}
          </Role>
        );
      })}
    </div>
  );
}
export default RolesFormField;
