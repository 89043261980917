import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Badge, Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';

import { use } from '../../redux/Factory';
import {
  SmallSpinner,
  EditButton,
  CogButton,
  CreateButton,
  HideButton,
  ShowButton,
  CreateEditModal as EditModal,
  DeleteConfirmButton,
  DataTable,
} from '../shared/ReactToolbox';
import { updateFormFields } from './Project';
import TaskTemplate from '../production/TaskTemplate';
import TeamleaderCustomFieldDefinitionsFormField from '../teamleader/TeamleaderCustomFieldDefinitionsFormField';
import TeamleaderClient from '../teamleader/TeamleaderClient';
import CreateProjectModal from './CreateProjectModal';

export const ProjectsDataTable = ({ projects, additionalActions: AdditionalActions }) => {

  const { moveProject, updateProject, deleteProject, showProject, hideProject } = use.projects();
  const { teamleaderCustomFieldDefinitionsList } = use.teamleaderCustomFieldDefinitions();
  const { teamleaderDealPhasesList } = use.teamleaderDealPhases();
  const { getTeamleaderDeal } = use.teamleaderDeals();
  const { productionTemplatesList } = use.productionTemplates();
  const { taskTemplatesList } = use.taskTemplates();
  const { settingsList } = use.settings();
  const [projectInEditModal, setProjectInEditModal] = useState(null);

  const cfd = settingsList.teamleader_custom_field_definitions_in_projects_table;
  const tcfdl = teamleaderCustomFieldDefinitionsList

  const columns = [
    {
      name: '#',
      orderBy: ({ order }) => order,
      selector: ({ order }) => order + 1,
    },
    {
      orderBy: ({ teamleader_deal: td }) => td.contact && td.contact.last_name,
      name: 'Bedrijfsentiteit',
      selector: ({ teamleader_deal: td }) => td.department
        ? <a href={td.data.web_url} target='_blank' rel='noreferrer'>{td.department.name}</a>
        : <i>Niet gevonden</i>
    },
    {
      orderBy: ({ teamleader_deal: td }) => td.contact && td.contact.last_name,
      name: 'Klant',
      selector: ({ teamleader_deal: td }) => <TeamleaderClient teamleaderDeal={td} />,
    },
    {
      orderBy: ({ teamleader_deal: td }) => td.data.reference,
      name: 'Dealnummer',
      selector: project => project.teamleader_deal && <>
        <Link to={`${project.id}`}>
          {project.teamleader_deal.data.reference || <i>Niet gevonden</i>}
        </Link>
      </>,
    },
    {
      name: 'Fase',
      orderBy: ({ teamleader_deal: td }) => (
        ((td && teamleaderDealPhasesList[td.phase]) || {}).name || ''
      ),
      selector: ({ teamleader_deal: td }) => {
        const phase = td && teamleaderDealPhasesList[td.phase];
        if (!phase) {
          return <i>Niet gevonden</i>;
        }
        return phase.name;
      },
    },
    {
      name: 'Productietemplate',
      selector: ({ production_template }) => {
        const productionTemplate = productionTemplatesList[production_template];
        if (!productionTemplate) return <i>Niet gevonden</i>;
        return productionTemplate.name || `#${productionTemplate.id}`
      }
    },
    {
      name: 'Lopende taken',
      selector: ({ tasks }) => {
        const startedTasks = tasks.filter(({ status }) => status === 'started' || status === 'unassigned');
        if (startedTasks.length === 0) return <i>Geen taken</i>;
        return startedTasks.map((task, index) => {
          const taskTemplate = taskTemplatesList[task.task_template];
          if (!taskTemplate) {
            return <Badge bg="warning" key={index}><i>Niet gevonden</i></Badge>
          }
          return (
            <TaskTemplate
              key={index}
              taskTemplate={taskTemplate}
              status={task.status}
            />
          );
        });

      }
    },
    ...cfd.value.reduce((o, id) => ([
      ...o,
      {
        name: tcfdl[id].label,
        orderBy: ({ teamleader_deal: td }) =>
          (
            td.custom_fields.find(cf => cf.definition === id) || {}
          ).value || '',
        selector: ({ teamleader_deal: td }) => {
          const cf = td.custom_fields.find(cf => cf.definition === id)
          if (!cf || cf.value === null) return <i>Leeg</i>
          return tcfdl[id].type === 'date' ? moment(cf.value).format('DD-MM-YYYY') : cf.value;
        },
      }
    ]), []),
    {
      name: 'Aangemaakt op',
      orderBy: 'created_at',
      selector: ({ created_at }) => moment(created_at).format('DD-MM-YYYY HH:mm'),
    },
    {
      name: 'Acties',
      selector: project =>
        <span>
          {AdditionalActions && <AdditionalActions project={project} />}
          {project.hidden
            ? <ShowButton
                onClick={() => showProject(project)}
                loading={showProject.isLoading && showProject.data.id === project.id}
                className='float-end'
                title='Verplaats naar tabblad "Projecten"'
              />
            : <HideButton
                onClick={() => hideProject(project)}
                loading={hideProject.isLoading && hideProject.data.id === project.id}
                className='float-end'
                title='Verbergen'
              />
          }
          <DeleteConfirmButton
            className="float-end"
            onDelete={async () => {
              const teamLoaderDeal = project?.teamleader_deal;
              await deleteProject(project)
              await getTeamleaderDeal(teamLoaderDeal?.id)
            }}
          />
          <EditButton
            className="float-end"
            onClick={() => setProjectInEditModal(project)}
          />
        </span>,
    }
  ];
  return (
    <>
      <DataTable
        rowsPerPage={50}
        filterColumn={({ name, teamleader_deal: td, production_template }) => `${
          name
        } ${
          td.data.title
        } ${
          td.company ? td.company.name : ''
        } ${
          td.contact && td.contact.first_name ? td.contact.first_name : ''
        } ${
          td.contact && td.contact.last_name ? td.contact.last_name : ''
        } ${
          td.contact && td.contact.first_name ? td.contact.first_name : ''
        } ${
          productionTemplatesList[production_template] ? productionTemplatesList[production_template].name : ''
        }`}
        columns={columns}
        data={projects}
        onMove={({ item, target, reset }) => moveProject(
          {
            project: item,
            target,
            position: item.order > target.order ? 'right' : 'left',
          },
          {
              callback: reset,
              onError: reset,
          }
        )}
        orderByDefault='order'
      />
      {projectInEditModal &&
        <EditModal
          onHide={() => setProjectInEditModal(null)}
          modalTitle="Project aanpassen"
          loading={updateProject.isLoading}
          initialState={projectInEditModal}
          formFields={updateFormFields}
          onSave={project => updateProject(
            project,
            { callback: () => setProjectInEditModal(null) }
          )}
        />
      }
    </>
  )
}
const ProjectsList = () => {
  const {
    projectsList,
    getProjectsList,
    hideProject,
    showProject,
  } = use.projects();

  const navigate = useNavigate();
  const { updateSetting, settingsList } = use.settings();
  const cfd = settingsList.teamleader_custom_field_definitions_in_projects_table;

  const { getTeamleaderDealsList, getTeamleaderDeal, teamleaderDealsList } = use.teamleaderDeals();
  const { teamleaderCustomFieldDefinitionsList } = use.teamleaderCustomFieldDefinitions();
  const { teamleaderDealPhasesList } = use.teamleaderDealPhases();
  const { productionTemplatesList } = use.productionTemplates();
  const { taskTemplatesList } = use.taskTemplates();
  useEffect(() => {
    getProjectsList();
    getTeamleaderDealsList();
  }, []);

  const [customFieldDefinitionsModalActive, setCustomFieldDefinitionsModalActive] = useState(false);
  const [activeTab, setActiveTab] = useState('projects');
  const [teamleaderDealInProjectModal, setTeamleaderDealInProjectModal] = useState(null)

  if (
    !projectsList
    || !teamleaderDealsList
    || !settingsList
    || !teamleaderCustomFieldDefinitionsList
    || !teamleaderDealPhasesList
    || !productionTemplatesList
    || !taskTemplatesList
  ) return <SmallSpinner className='vertical-space' />;

  return <>
    <Container className="container-list container-table">
      <Tabs
        activeKey={activeTab}
        onSelect={tab => {
          setActiveTab(tab);
        }}
        className="mb-3"
      >
        {Object.entries({
          projects: "Projecten",
          finished: "Afgemaakt",
        }).map(([eventKey, title], key) => (
          <Tab {...{ eventKey, title, key }} />
        ))}
      </Tabs>

      {projectsList &&
        <ProjectsDataTable
          projects={
            Object.values(projectsList)
              .filter(({ teamleader_deal: td, hidden }) =>
                td && teamleaderDealPhasesList[td.phase] && (
                  activeTab === 'projects'
                  ? !teamleaderDealPhasesList[td.phase].hide_deals && !hidden
                  : teamleaderDealPhasesList[td.phase].hide_deals || hidden
                )
              )
          }
          showHideButton={activeTab === 'projects'}
          additionalActions={({ project }) =>
            <>
              <CreateButton
                className='float-end'
                onClick={() => setTeamleaderDealInProjectModal(project.teamleader_deal)}
                style={{ marginLeft: '4px' }}
                title='Nieuw project toevoegen aan offerte van dit project'
              />
            </>
          }
        />
      }

      <CogButton onClick={() => setCustomFieldDefinitionsModalActive(true)}>
        Kolommen beheren
      </CogButton>


      {customFieldDefinitionsModalActive &&
        <EditModal
          onHide={() => setCustomFieldDefinitionsModalActive(false)}
          modalTitle="Kolommen toevoegen"
          loading={updateSetting.isLoading}
          formFields={{
            value: {
              label: 'Offertevelden',
              component: TeamleaderCustomFieldDefinitionsFormField,
            }
          }}
          initialState={cfd}
          onSave={setting => updateSetting(
            setting,
            { callback: () => setCustomFieldDefinitionsModalActive(false) }
          )}
        />
      }

      {teamleaderDealInProjectModal &&
        <CreateProjectModal
          onHide={() => setTeamleaderDealInProjectModal(null)}
          onCreate={async ({ id }) => {
            await getTeamleaderDeal(teamleaderDealInProjectModal);
            setTeamleaderDealInProjectModal(null)
            navigate(`/projects/${id}`);
          }}
          teamleaderDeal={teamleaderDealInProjectModal}
        />
      }
    </Container>
  </>;
};
export default ProjectsList;
